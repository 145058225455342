const supportedLanguages = import.meta.env.VITE_APP_I18N_SUPPORTED_LANGUAGES.split(' ');

export const validate = value => {
  if (!value) {
    return;
  }

  if (!(supportedLanguages.indexOf(value.toLowerCase()) > -1)) {
    throw new Error(`Language "${value}" is not supported`);
  }
};
