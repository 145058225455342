import engine from 'store';
import storages from 'store/storages/all';
import memoryStorage from 'store/storages/memoryStorage';
import cookieStorage from 'store/storages/cookieStorage';
import expirePlugin from 'store/plugins/expire';
import { isLocalStorageEnabled } from '@/utils/isLocalStorageEnabled';
import { isCookieEnabled } from '@/utils/isCookieEnabled';

let storage;
const canUseCookie = isCookieEnabled();
const canUseLocalStorage = isLocalStorageEnabled();

switch (true) {
  case canUseCookie && !canUseLocalStorage:
    storage = engine.createStore([cookieStorage], [expirePlugin]);
    break;

  case !canUseCookie && !canUseLocalStorage:
    storage = engine.createStore([memoryStorage], [expirePlugin]);
    break;

  default:
    storage = engine.createStore(storages, [expirePlugin]);
    break;
}

export const StorageService = {
  get(key) {
    return storage.get(key);
  },
  set(key, data, options) {
    return storage.set(key, data, options);
  },
  remove(key, options) {
    return storage.remove(key, options);
  },
};
