/* HACK:
    Fixes the host for images assets. frontend-common-lib expects to get assets from the same origin where it runs.
    But now frontend-common-lib is runed on both https://paybis.com and widget. And resources stored only on https://paybis.com
    In future we want to host assets at some CDN. Until that time assets are sotred at https://paybis.com. And this
    mixin adds host to image pathes only in widget, but at https://paybis.com it still works as used to.
*/

const imageHost = import.meta.env.VITE_APP_ASSETS_HOST;
const componentName = 'CurrentTransaction';

function updateAssetsHost(sameLinkHost) {
  if (sameLinkHost.startsWith(imageHost)) return sameLinkHost;
  return `${imageHost}${sameLinkHost}`;
}

const externalAssetsHostReplaceMixin = {
  beforeCreate() {
    if (this.$options.name !== componentName) return;

    this.transaction.sell.icon = updateAssetsHost(this.transaction.sell.icon);
    this.transaction.buy.icon = updateAssetsHost(this.transaction.buy.icon);
  },
  beforeUpdate() {
    if (this.$options.name !== componentName) return;

    this.transaction.sell.icon = updateAssetsHost(this.transaction.sell.icon);
    this.transaction.buy.icon = updateAssetsHost(this.transaction.buy.icon);
  },
};

export default externalAssetsHostReplaceMixin;
