/* eslint-disable no-underscore-dangle */

export function loadHotJarScript(id) {
  if (!window) {
    return;
  }

  window._hjSettings = { hjid: id, hjsv: 6 };

  const script = document.createElement('script');
  script.src = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;
  script.async = true;
  document.head.appendChild(script);
}
