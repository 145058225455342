// XXX: set default as v1
const apiVersion = {
  version: 'v1',
};

let waitApiVersionResolver;
export const waitApiVersion = new Promise(resolve => {
  waitApiVersionResolver = resolve;
});

export const setApiVersion = version => {
  apiVersion.version = version;
  waitApiVersionResolver();
};

export const isV1 = () => apiVersion.version === 'v1';
export const isV2 = () => apiVersion.version === 'v2';
