import LedgerLiveApi, { WindowMessageTransport } from '@ledgerhq/live-app-sdk';
import { isElectron } from '@/utils/detect';

const api = { current: null };

export const initLedger = () => {
  if (!isElectron()) {
    return;
  }

  const llapi = new LedgerLiveApi(new WindowMessageTransport());
  llapi.connect();

  api.current = llapi;
};

export const destroyLedger = () => {
  if (!api.current) {
    return;
  }

  api.current.disconnect();
};

export const requestAccount = params => {
  if (!api.current) {
    return Promise.resolve(null);
  }

  return api.current.requestAccount(params);
};
