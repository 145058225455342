import { DeviceIdentificationError } from '@/exceptions/DeviceIdentificationError';

const NSURE_60SEC_TIMEOUT_MS = 60 * 1000;

// Script error promise
let scriptErrorResolve = null;

const scriptErrorPromise = new Promise(resolve => {
  scriptErrorResolve = resolve;
});

// Nsure Initialization promise
let appIdResolve = null;

const appIdPromise = new Promise(resolve => {
  appIdResolve = resolve;
});

export const setAppIdPartnerId = (newAppId, newPartnerId) => {
  appIdResolve({ appId: newAppId, partnerId: newPartnerId });
};

// Device ID promise
let deviceIdResolve = null;
let deviceIdReject = null;

const deviceIdPromise = new Promise((resolve, reject) => {
  deviceIdResolve = resolve;
  deviceIdReject = reject;
});

export const getDeviceId = async () => {
  const result = await Promise.race([deviceIdPromise, scriptErrorPromise]);

  if (result instanceof DeviceIdentificationError) {
    throw result;
  }

  return result;
};

// Nsure SDK Initialization
window.nSureAsyncInit = async deviceId => {
  deviceIdResolve(deviceId);

  if (~nSureTimeout) {
    clearTimeout(nSureTimeout);
  }

  const { appId, partnerId } = await appIdPromise;

  if (partnerId) {
    window.nSureSDK.init(appId, partnerId);
  } else {
    window.nSureSDK.init(appId);
  }
};

// Nsure Script Initialization
let nSureTimeout = -1;
let isScriptInitialized = false;

export const initNSure = () => {
  if (isScriptInitialized) {
    return;
  }

  const script = document.createElement('script');
  script.src = import.meta.env.VITE_APP_NSURE_SCRIPT_URL;
  script.async = true;
  script.addEventListener('error', onNsureLoadingError);
  document.body.appendChild(script);

  // Clear timeout if script cannot be loaded during long time
  nSureTimeout = setTimeout(() => {
    scriptErrorResolve(new DeviceIdentificationError());
  }, NSURE_60SEC_TIMEOUT_MS);

  isScriptInitialized = true;
};

const onNsureLoadingError = () => {
  scriptErrorResolve(new DeviceIdentificationError());

  if (~nSureTimeout) {
    clearTimeout(nSureTimeout);
  }
};
