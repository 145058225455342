// eslint-disable-next-line no-multi-assign,no-underscore-dangle
const siftConfig = window._sift = window._sift || [];

export const setSiftAccount = beacon => {
  siftConfig.push(['_setAccount', beacon]);
};

export const setSiftUserId = userId => {
  siftConfig.push(['_setUserId', userId]);
};

export const setSiftSessionId = value => {
  siftConfig.push(['_setSessionId', value]);
};

export const trackPageview = () => siftConfig.push(['_trackPageview']);
