export const isOneClickFlowCanBeUsed = (quote, requestPaymentMethod) => {
  const {
    paymentMethods,
    payoutMethods,
  } = quote;

  let quotePaymentMethod;
  if (paymentMethods && paymentMethods.length === 1) {
    quotePaymentMethod = paymentMethods[0].id;
  } else if (payoutMethods && payoutMethods.length === 1) {
    quotePaymentMethod = payoutMethods[0].id;
  }

  return quotePaymentMethod && requestPaymentMethod === quotePaymentMethod;
};
