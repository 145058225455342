import { handleUrlParams as handleLedgerUrlParams, supports as supportsLedgerParams } from './ledger/params';
import { handleUrlParams as handlePaybisUrlParams, supports as supportsPaybisParams } from './paybis/params';

export const handleUrlParams = urlParams => {
  if (supportsLedgerParams(urlParams)) {
    return handleLedgerUrlParams(urlParams);
  }

  if (supportsPaybisParams(urlParams)) {
    return handlePaybisUrlParams(urlParams);
  }

  return Array.from(urlParams.entries()).reduce((result, parameter) => {
    result[parameter[0]] = parameter[1];

    return result;
  }, {});
};
