import { ref, computed } from 'vue';
import { FRICTIONLESS_MODES } from '@/constants/frictionless';

const frictionlessMode = ref('manual');

export const depositCallbackUrl = ref('');
export const isPaymentInitiated = ref(false);

export const isFrictionlessEnabled = computed(() => {
  return frictionlessMode.value && frictionlessMode.value !== 'manual';
});

export const setFrictionlessMode = value => {
  frictionlessMode.value = value;
};

export const isRedirectMode = computed(() => {
  return frictionlessMode.value === FRICTIONLESS_MODES.REDIRECT;
});

export const setIsPaymentInitiated = status => {
  if (status !== 'payment_initiated') return;
  isPaymentInitiated.value = true;
};

export const setDepositCallbackUrl = url => {
  depositCallbackUrl.value = url;
};
