export function inputScrollHotfix() {
  if (/Android/.test(navigator.appVersion)) {
    window.addEventListener('click', () => {
      if (document.activeElement.tagName === 'INPUT') {
        document.body.classList.add('stop-scrolling');
      }
    });

    window.addEventListener('touchstart', () => {
      document.body.classList.remove('stop-scrolling');
    });
  }
}
