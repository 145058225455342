import { isLocalStorageEnabled } from '@/utils/isLocalStorageEnabled';

const noopStorage = {
  length: 0,
  clear: () => {},
  getItem: () => {},
  key: () => {},
  removeItem: () => {},
  setItem: () => {},
};

const getStorage = () => {
  if (!isLocalStorageEnabled()) {
    return noopStorage;
  }

  return window.localStorage;
};

export default getStorage();
