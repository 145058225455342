import { AppCriticalError } from '@/exceptions/AppCriticalError';

const requestIdRegex = /^[a-z,0-9,-]{36,36}$/;

export const validate = value => {
  if (!value) {
    throw new AppCriticalError('Missing requestId');
  }

  if (!requestIdRegex.test(value)) {
    throw new AppCriticalError('Invalid requestId');
  }
};
