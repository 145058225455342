import closeWindow from '@/utils/closeWindow';
import { isInsideIframe } from '@/utils/isInsideIframe';

const WIDGET_EVENT_NAMESPACE = 'widget';

const STATE_CLOSED = 'closed';
const STATE_CANCELLED = 'cancelled';
const STATE_REJECTED = 'rejected';
const STATE_COMPLETED = 'completed';

const CLOSE_STATES = [STATE_CLOSED, STATE_CANCELLED, STATE_REJECTED, STATE_COMPLETED];

export const emitToParent = (eventName, payload) => {
  const { state } = payload;

  if (!isInsideIframe() && CLOSE_STATES.includes(state)) {
    closeWindow();

    return;
  }

  window.parent.postMessage(JSON.stringify({
    namespace: WIDGET_EVENT_NAMESPACE,
    name: eventName,
    payload,
  }), '*');
};

export const STATES = {
  STATE_CLOSED,
  STATE_REJECTED,
  STATE_CANCELLED,
  STATE_COMPLETED,
};
