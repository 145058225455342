const TEST = '__localStorageTest__';

export const isLocalStorageEnabled = () => {
  // try to use localStorage, and if it does not give any error, then it is available
  try {
    if (!window || !window.localStorage) {
      return false;
    }

    window.localStorage.setItem(TEST, TEST);
    window.localStorage.removeItem(TEST);
  } catch {
    return false;
  }

  return true;
};
