export const MENU_ITEMS_DICTIONARY = {
  LANGUAGE: {
    alias: 'language',
    order: 1,
    translationCode: 'widget.language',
  },
  TRANSACTION_HISTORY: {
    alias: 'transaction-history',
    order: 2,
    translationCode: 'widget.menu.items.transaction-history',
  },
  TERMS_OF_USE: {
    alias: 'terms-of-use',
    order: 5,
    translationCode: 'widget.menu.items.terms-of-use',
  },
};
