/* eslint-disable no-underscore-dangle */

import EventBus from 'eventing-bus';

if (!window.__pb_eventBus) {
  window.__pb_eventBus = EventBus;
}

const eventBusInstance = window.__pb_eventBus;

eventBusInstance.emit = (eventName, payload) => eventBusInstance.publish(eventName, payload);
eventBusInstance.off = eventName => eventBusInstance.unregisterCallbacksForEvent(eventName);
eventBusInstance.detach = (eventName, callback) => eventBusInstance.unregisterCallback(callback);

export default eventBusInstance;
