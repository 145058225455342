import { AppCriticalError } from './AppCriticalError';

export const DEVICE_IDENTIFICATION_ERROR = 'DeviceIdentificationError';
export class DeviceIdentificationError extends AppCriticalError {
  constructor(message) {
    super(message);
    this.message = message;
    this.name = DEVICE_IDENTIFICATION_ERROR;
  }
}
